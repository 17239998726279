import React, { useState, useEffect } from "react";
import * as BsIcon from "react-icons/bs";
import * as HiIcon from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import app, { db } from "../../../data/base";
import logo from "../../../assets/images/logo.png";
import BrandSelectorInput from "./brandInput";
import Avatar from "../avatar/index";
import { logStorageOut } from "../../../data/utils";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "../../../redux/config/config";
import { User } from "react-feather";
import { getSidebarData, getValidatorSidebar } from "./navBarData";

function Navbar(props) {
  const [sidebar, setSidebar] = useState(true);
  const isIcon = useSelector((state) => state.config.isIconView);
  const [hoverIndex, setHoverIndex] = useState(0);
  const dispatch = useDispatch();

  const [isFetch, setIsFetch] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [isAdmin, setRole] = useState(false);
  const [isValidator, setIsValidator] = useState(false);
  const isARShadesStudioModeller = props.isARShadesStudioModeller;
  const { pathname } = useLocation();
  const showSidebar = () => setSidebar(!sidebar);

  const [sidebarData, setSidebarData] = useState([]);
  const [validatorSidebarData, setValidatorSidebarData] = useState([]);

  const getUserData = async () => {
    const accountRef = await db.collection("Profile").doc(localStorage.getItem("email").toLowerCase()).get();
    const clientiRef = await db.collection("Client").get();
    if (accountRef.data()) {
      setRole(accountRef.data().role === "Admin");
      if (accountRef.data().role === "Validator") {
        setIsValidator(true);
      }
    }
    clientiRef.docs.map((doc) => {
      const data = doc.data();
      if (localStorage.getItem("email") === data.idAccount) {
        setProfilePhoto(data.photoURL);
      }
    });
    setIsFetch(true);
  };

  const toggleMenu = (state, index) => {
    dispatch(configActions.setMenuIcon(state));
    setHoverIndex(index);
  };

  const getUserNotif = async () => {
    const accRef = db.collection('Profile').doc(localStorage.getItem('email'));
    const notifs = await db.collection("Lista_Notification")
      .where('accRef', '==', accRef)
      .where('status', '==', "unread")
      .orderBy('sendTime', 'desc')
      .limit(7)
      .get();
    setNotifCount(notifs.size);
  };

  useEffect(() => {
    if (!isFetch) {
      getUserData();
      getUserNotif();
    }
    function handleResize() {
      setSidebar(window.innerWidth >= 1000);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [isFetch]);

  useEffect(() => {
    // Ricarica i dati della sidebar quando viene effettuato il login o quando cambia `canPlaceOrders`
    setSidebarData(getSidebarData());
    setValidatorSidebarData(getValidatorSidebar());
  }, [isFetch]);

  const logOut = () => {
    logStorageOut();
    app.auth().signOut();
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleSidebar = () => setIsExpanded(!isExpanded);

  return (
    <>
      <IconContext.Provider value={{}}>
        {!isARShadesStudioModeller && (
          <div className="navbar">
            <div className="nav-content">
              <Link to="#" className="menu-bars">
                <HiIcon.HiOutlineMenuAlt2
                  onClick={showSidebar}
                  className="nav-menu-icon"
                />
              </Link>
              <div className="nav-heading">
                {pathname
                  .split("/")[1]
                  .replace(/^\w/, (c) => c.toUpperCase())
                  .replace(/([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, "$1$2 ")}
              </div>
            </div>

            <div className="nav-content">
              <BrandSelectorInput pathname={pathname} />
              <Avatar photoURL={profilePhoto} logOut={logOut} />
            </div>
          </div>
        )}

        <nav className={sidebar ? "nav-menu active" : "nav-menu"} style={{ width: isIcon ? 100 : null }}>
          <ul className="nav-menu-items">
            <div className="top-container">
              <div className="logo-container">
                <img src={logo} alt="logo" className="logo" />
                {!isIcon && <div className="logo-text-container">
                  <p className="logo-text">AR STUDIO</p>
                </div>}
              </div>
            </div>
            <div className="divider" />
            {isValidator ? (
  // Render Sidebar per Validator
  validatorSidebarData.map((item, index) => (
    <li
      key={index}
      className={`${item.cName}`}
      onMouseEnter={() => toggleMenu(false, index)}
      onMouseLeave={() => toggleMenu(true, 10)}
    >
      <Link to={item.path}>
        {item.icon}
        {!isIcon && <span>{item.title}</span>}
        {item.hasNotifications && (
          <span
            className="badge position-absolute bg-danger rounded-circle"
            style={{ right: 10, marginBottom:20, fontSize: 12 }}
          >
            {notifCount}
          </span>
        )}
      </Link>
    </li>
  ))
) : (
  // Render Sidebar per gli altri utenti
  sidebarData.map((item, index) => (
    (item.isAdmin && !isAdmin) ? null : (
      <li
        key={index}
        className={`${item.cName}`}
        onMouseEnter={() => toggleMenu(false, index)}
        onMouseLeave={() => toggleMenu(true, 10)}
      >
        <Link to={item.path}>
          {item.icon}
          {!isIcon && <span>{item.title}</span>}
          {item.hasNotifications && (
            <span
              className="badge position-absolute bg-danger rounded-circle"
              style={{ right: 10, top: 230, fontSize: 12 }}
            >
              {notifCount}
            </span>
          )}
        </Link>
      </li>
    )
  ))
)}

            <div className="divider">
              <Link to="#" className="menu-bars back-button">
                <BsIcon.BsArrowLeft
                  onClick={showSidebar}
                  className="nav-icon back-arrow"
                />
              </Link>
            </div>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;